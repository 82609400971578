const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  access_token: state => state.mapiUser.access_token,
  access_token_expires_in: state => state.mapiUser.access_token_expires_in,
  advertiser_id: state => state.mapiUser.advertiser_id,
  refresh_token: state => state.mapiUser.refresh_token,
  refresh_token_expires_in: state => state.mapiUser.refresh_token_expires_in,
  phoneNumber: state => state.user.phoneNumber,
  user_id: state => state.mapiUser.user_id,
  tab: state => state.tab.isCollapse
}
export default getters
