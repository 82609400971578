import { getData } from '@/api/request.js'

// 登录方法
export function login(username, password, code, uuid, sessionId, sig, token) {
  const data = {
    username,
    password,
    code,
    uuid,
    sessionId,
    sig,
    token
  }
  return getData({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 手机验证
export function smsLogin(phonenumber, smsCode) {
  const data = {
    phonenumber,
    smsCode,
  }
  return request({
    url: '/smsLogin',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取手机短信验证码
export function getPhoneCaptcha(data) {
  return request({
    url: '/send/'+data,
    method: 'get'
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
