<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <h3 class="title">MAPI导出系统</h3>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          type="text"
          auto-complete="off"
          placeholder="账号"
        >
          <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="密码"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
        </el-input>
      </el-form-item>
      <el-form-item prop="code" v-if="captchaOnOff">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 63%"
          @keyup.enter.native="handleLogin"
        >
          <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
        </el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        </div>
      </el-form-item>

      <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>

      <el-form-item style="width:100%;">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width:100%;"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
        <div style="float: right;" v-if="register">
          <router-link class="link-type" :to="'/register'">立即注册</router-link>
        </div>
      </el-form-item>
    </el-form>

    <!--  底部  -->
    <div class="el-login-footer">
      <span>Copyright © 2018-2022 ruoyi.vip All Rights Reserved.</span>
    </div>

    <el-dialog
      title="手机验证"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <div class="pt-20 pr-15 pl-15 pb-20" style="font-size: 16px">
        <div style="margin-bottom: 5px;">
          <span style="margin-right: 20px;margin-bottom: 10px">您绑定的手机：</span>
          <span style="font-weight: 600;font-size: 18px">{{phoneNumber.substring(0, 3)}}****{{phoneNumber.substring(phoneNumber.length-4)}}</span><br>
        </div>
        <div class="my-box">
          <span>短信验证码：</span>
          <el-input v-model="phoneCaptcha" style="width: 120px;margin:0 0 10px 35px" placeholder="请输入验证码"></el-input>
          <el-button  :disabled="disabled" style="margin-left:10px" @click="getPhoneCode" type="primary">{{ title }}</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="closePhoneCaptcha">取 消</el-button>
    <el-button type="primary" @click="smsLogin">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getCodeImg, getPhoneCaptcha } from '@/api/login'
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt'

export default {
  name: "Login",
  computed: {
    ...mapGetters(['phoneNumber']),
    title() {
      return this.disabled ? `重新获取 ( ${this.second} ) s` : '获取验证码';
    },
  },
  data() {
    return {
      codeUrl: "",
      dialogVisible:false,
      phoneCaptcha:"",
      second: '', //倒计时
      disabled: false, //是否禁用按钮
      timer: null, //计时器
      loginForm: {
        username: undefined,
        password: undefined,
        rememberMe: false,
        code: "",
        uuid: "",
        sessionId:"",
        sig:"",
        token:"",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }],
        smartCaptcha:[{ required: true, trigger: "change", message: "请通过智能验证" }],
      },
      loading: false,
      // 验证码开关
      captchaOnOff: true,
      // 注册开关
      register: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   // 在组件 mounted 钩子函数中获取组件实例
    //   this.$refs.captchaRef.reset() // 调用 reset 方法
    // });

    const oScript = document.createElement('script');
    oScript.type = 'text/javascript';
    oScript.src = 'https://g.alicdn.com/AWSC/AWSC/awsc.js';
    document.body.appendChild(oScript);
  },
  created() {
    // this.getCode();
    // this.getCookie();
  },
  methods: {
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.data.captchaOnOff === undefined ? true : res.data.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.data.img;
          this.loginForm.uuid = res.data.uuid;
        }
      });
    },
    // reloadCaptcha() {
    //   this.$nextTick(() => {
    //     this.$refs.captchaRef.reset() // 调用 reset 方法
    //   })
    // },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    slideYzSuccess(data) {
      //会话ID（sessionId）、签名串（sig）、请求唯一标识（token）
      this.loginForm.sessionId=data.sessionId
      this.loginForm.sig=data.sig
      this.loginForm.token=data.token
    },
    getPhoneCode(){
      getPhoneCaptcha(this.phoneNumber).then(response => {
        this.$modal.msgSuccess("已发送验证码");
      }).catch(() => {
        this.$modal.msgError("发送验证码失败");
      });
      // console.log('点击')
      let that = this
      let s = 60  //倒计时间
      if (!that.timer) {
        that.second = s
        that.disabled = true
        that.timer = setInterval(() => {
          if (that.second > 0 && this.second <= s) {
            that.second--
          } else {
            that.disabled = false
            clearInterval(that.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    smsLogin(){
      const login = {
        phonenumber:this.phoneNumber,
        smsCode:this.phoneCaptcha
      }
      // console.log(login)
      this.$store.dispatch("smsLogin", login).then(() => {
        console.log("登录成功")

      }).finally(() => {
        this.$router.push({ path: this.redirect || "/" }).catch(()=>{});
      });
    },
    openPhoneCaptcha(){
      this.dialogVisible = true;
    },
    closePhoneCaptcha(){
      this.getCode();
      // this.reloadCaptcha();
      this.loading=false;
      this.dialogVisible = false;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
            Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          this.$store.dispatch("Login", this.loginForm).then(() => {
            this.openPhoneCaptcha();
          }).catch(() => {
            this.loading = false;
            if (this.captchaOnOff) {
              this.getCode();
              // this.reloadCaptcha();
            }
          });
        }
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("../assets/images/login-background.jpg");
  background-size: cover;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 38px;
}
.phoneCaptcha-button{
  margin-left: 5px;
  text-align: center;
}

.get-mobile {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;

  .main {
    width: 280px;
    height: 178px;
    background: #FFFFFF;
    border-radius: 5px;

    .my-box{
      display: flex;
      flex-direction: row;
    }


  }
}


</style>
