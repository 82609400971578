import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const ProjectKey = 'pages-project'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getProject() {
  const str = localStorage.getItem(ProjectKey)
  return str ? JSON.parse(str) : null
}

export function settingProject(project) {
  return localStorage.setItem(ProjectKey, JSON.stringify(project))
}

export function removeProject() {
  return localStorage.removeItem(ProjectKey)
}
