<template>
  <div style="padding: 20px;height: auto;display: flex;flex-direction: column;align-items: center;justify-content: center;">
    <h1 style="margin-bottom: 50px">聚星星任务-视频流量助推投后数据</h1>
    <el-form ref="form" :model="preFrom" label-width="80px">
      <el-form-item label="账户ID">
        <el-select v-model="preFrom.id" filterable placeholder="请选择账户ID">
          <el-option
              v-for="item in accountIds"
              :key="item.id"
              :label="item.accountName"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建日期">
        <el-date-picker
            v-model="createTime"
            type="datetimerange"
            align="right"
            range-separator="至"
            start-placeholder="	创建开始时间"
            end-placeholder="	创建结束时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            :picker-options="pickerOptions"
            @change="getDate"
        />
        <p style="color: #a9a9a9;margin: 0">时间最长区间为30天</p>
      </el-form-item>
      <el-form-item label="效果日期(选填)">
        <el-date-picker
            v-model="reportTime"
            type="datetimerange"
            align="right"
            range-separator="至"
            start-placeholder="	创建开始时间"
            end-placeholder="	创建结束时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            :picker-options="pickerOptions2"
            @change="getDateByReportTime"
        />
        <p style="color: #a9a9a9;margin: 0">效果时间最长区间为59天</p>
      </el-form-item>
      <el-form-item style="display: flex; align-items: center;justify-content: center">
        <el-button type="primary" :loading="loading" @click="onSubmit">立即获取</el-button>
        <el-button type="success" @click="handlerExportRecord">导出记录</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :title="exportRecord.title" :visible.sync="exportRecord.open" append-to-body>
      <el-table v-loading="loading" :data="exportRecord.dataList">
        <el-table-column label="导出账户" align="center" prop="exporterName" />
        <el-table-column label="导出时间" align="center" prop="exportTime" width="180">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.exportTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文件名" align="center" prop="fileName" />
        <el-table-column label="导出状态" align="center" prop="exportState"/>
        <el-table-column label="错误原因" align="center" prop="failReason"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-link :href="scope.row.exportPath">下载</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.size"
          @pagination="getExportList"
      />
    </el-dialog>
  </div>
</template>

<script>
import {parseTime} from "@/utils/untilsMethod";
import {exportList} from "@/api/exportFile";
import {getJXAccountList} from "@/api/jxAccount";
import {exportSupplement} from "@/api/jxHandle";

export default {
  data() {
    return {
      loading: false,
      // 订单导出记录
      exportRecord: {
        open: false,
        title: '',
        dataList: []
      },
      total:0,
      queryParams: {exportType:16,page:1,size:20},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        },
          // {
          //   text: '最近三个月',
          //   onClick(picker) {
          //     const end = new Date()
          //     const start = new Date()
          //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          //     picker.$emit('pick', [start, end])
          //   }
          // }
        ]
      },
      pickerOptions2: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        },
          {
            text: '最近59天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 59)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      createTime: undefined,
      reportTime: undefined,
      accountIds: [],
      preFrom: {
        id: undefined,
        createBeginTime: undefined, // 创建日期 开始
        createEndTime: undefined, // 创建日期 结束
        reportBeginTime: undefined, // 有效日期 开始
        reportEndTime: undefined, // 有效日期 结束
        pageNum: 1, // 页码
        pageSize: 1000, // 页长
        supplementOrderType: 3, //助推类型  1.星视频 2.星直播 3.星任务(视频)
        value: "", // 搜索关键词
        type: 1 // 搜索类型
      },
      form: {}
    }
  },
  mounted() {
    this.getAccountList()
  },
  methods: {
    parseTime,
    getAccountList() {
      getJXAccountList().then(response => {
        this.accountIds = response.data
      }).catch(response => {
        this.$message.error(response.msg)
      })
    },
    handlerExportRecord() {
      return exportList({"exportType":16,"page":1,"size":20}).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
        this.exportRecord.open=true
        this.exportRecord.title="星任务(视频)助推导出记录"
      })
    },
    getExportList(){
      return exportList(this.queryParams).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
      })
    },
    async onSubmit() {
      if (!this.preFrom.id) {
        this.$message.error("账户ID不能为空");
        return;
      }
      if (!this.preFrom.createBeginTime || !this.preFrom.createEndTime) {
        this.$message.error("创建日期不能为空");
        return;
      }
      this.loading = true
      this.getForm()
      exportSupplement(this.form).then(response => {
        this.$message.success('[星任务助推]'+response.msg);
      }).catch(() => {
        this.$message.error('请求失败');
      })
      this.$message.warning('导出中...');
      this.reset();
      this.loading = false;
    },
    reset() {
      this.form = {}
    },
    getForm() {
      this.form = JSON.parse(JSON.stringify(this.preFrom));
    },
    getDate() {
      console.log(this.createTime)
      if (this.createTime) {
        this.preFrom.createBeginTime = this.createTime[0]
        this.preFrom.createEndTime = this.createTime[1]
      }
      if (this.createTime == null) {
        this.createTime = undefined // 清空开始时间和结束时间
        this.preFrom.createBeginTime = undefined
        this.preFrom.createEndTime = undefined
      }
    },
    getDateByReportTime(){
      console.log(this.reportTime)
      if (this.reportTime) {
        this.preFrom.reportBeginTime = this.reportTime[0]
        this.preFrom.reportEndTime = this.reportTime[1]
      }
      if (this.reportTime == null) {
        this.reportTime = undefined // 清空开始时间和结束时间
        this.preFrom.reportBeginTime = undefined
        this.preFrom.reportEndTime = undefined
      }
    }
  }
}
</script>

