<template>
  <div class="container">
    <el-form :loading="loading" style="display: flex;flex-direction: row;" ref="form" :model="preFrom" label-width="80px">
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
        >新建任务
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-refresh-right"
            size="mini"
            @click="getDataList"
        >刷新
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            size="mini"
            @click="handlerExportRecord"
        >导出记录
        </el-button>
      </el-col>
    </el-row>
    <el-table border stripe :loading="loading" :data="DataList" style="margin-top: 5px;">
      <el-table-column label="ID" align="center" prop="id"/>
      <el-table-column label="任务名称" align="center" prop="name"/>
      <el-table-column label="频率" align="center" prop="frequency"/>
      <el-table-column label="开始时间" align="center" prop="startDate">
        <template slot-scope="scope">
          {{parseTime(scope.row.startDate)}}
        </template>
      </el-table-column>
      <el-table-column label="结束时间" align="center" prop="endDate">
        <template slot-scope="scope">
          {{parseTime(scope.row.endDate)}}
        </template>
      </el-table-column>
      <el-table-column label="执行任务" align="center" prop="missionId" width="200px">
        <template slot-scope="scope">
          {{getMissionName(scope.row.missionId)}}
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" prop="frequency">
        <template slot-scope="scope">
          {{scope.row.status === 1 ? "运行中" : new Date() > new Date(scope.row.endDate) ? "已完成" : "暂停"}}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" prop="exporterName" width="140px">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              :icon="scope.row.status === 1 ? 'el-icon-video-pause' : 'el-icon-video-play'"
              @click="handleSwitch(scope.row.id,scope.row.status)"
          >{{scope.row.status === 1 ? "关闭" : "运行"}}
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row.id)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 提交弹窗 -->
    <el-dialog :title="addDialog.title" :visible.sync="addDialog.open" width="40%" append-to-body :close-on-click-modal=false>
      <el-form v-loading="loading" :model="preFrom" ref="ruleForm" label-position="left" inline
               style="display: flex;flex-direction: column;padding: 20px;" label-width="100px">
        <el-form-item label="定时名称" prop="missionName">
          <el-input v-model="preFrom.name" placeholder="请输入任务名称"></el-input>
        </el-form-item>
        <el-form-item label="运行任务" prop="description">
          <el-select v-model="preFrom.missionId" filterable placeholder="请选择">
            <el-option
                v-for="item in missionDataList"
                :key="item.id"
                :label="item.missionName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="频率" prop="description">
          <el-select v-model="preFrom.minute" filterable placeholder="请选择">
            <el-option
                v-for="item in minuteType"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="周期" prop="description">
          <el-select v-model="preFrom.dateType" filterable placeholder="请选择">
            <el-option
                v-for="item in dateType"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-top: 20px">
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button v-if="addDialog.type !='update'" @click="resetForm()">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 导出记录 -->
    <el-dialog :title="exportRecord.title" :visible.sync="exportRecord.open" append-to-body>
      <el-table v-loading="loading" :data="exportRecord.dataList">
        <el-table-column label="导出账户" align="center" prop="exporterName" />
        <el-table-column label="导出时间" align="center" prop="exportTime" width="180">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.exportTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文件名" align="center" prop="fileName" />
        <el-table-column label="导出状态" align="center" prop="exportState"/>
        <el-table-column label="错误原因" align="center" prop="failReason"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-link :href="scope.row.exportPath">下载</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.size"
          @pagination="getExportList"
      />
    </el-dialog>
  </div>
</template>

<script>
import {checkTokenBeforeRouteEnter} from '@/utils/authoVerify'
import {parseTime} from "@/utils/untilsMethod";
import {getSchedulerList, addScheduler, switchScheduler, deleteScheduler, updateScheduler} from "@/api/scheduler";
import {getMissionList} from "@/api/mission";
import { cloneDeep } from 'lodash';
import {exportList} from "@/api/exportFile";

export default {
  beforeRouteEnter(to, from, next) {
    checkTokenBeforeRouteEnter(to, from, next);
  },
  data() {
    return {
      loading: false,
      reverse: true,
      // 导出记录
      exportRecord: {
        open: false,
        title: '',
        dataList: []
      },
      total:0,
      queryParams: {exportType:12,page:1,size:70},

      actionType:[
        { id: 1, label: '星视频一体式导出' },
        { id: 2, label: '星任务(视频)一体式导出' },
        { id: 3, label: '星视频助推分时数据' },
        { id: 4, label: '星任务(视频)助推分时数据' },
      ],
      minuteType:[
        { id: 5, label: '5分钟' },
        { id: 15, label: '15分钟' },
        { id: 30, label: '30分钟' },
        { id: 45, label: '45分钟' },
        { id: 60, label: '1小时' },
        { id: 120, label: '2小时' },
      ],
      dateType: [
        { id: 1, label: '最近一天' },
        { id: 2, label: '最近一周' },
        { id: 3, label: '最近一个月' },
        { id: 4, label: '最近两个月' },
        { id: 5, label: '最近三个月' },
        { id: 6, label: '最近半年' },
        { id: 7, label: '最近一年' },
      ],
      // 任务数据
      missionDataList: [],
      DataList: [],
      // 新建任务弹窗
      addDialog: {
        open: false,
        title: '',
        type: '',
      },
      // 请求参数
      preFrom: {
        name: undefined,
        missionId: undefined,
        minute: 30,
        dateType: 1,
        status: undefined,
      },
      form: {}
    }
  },
  created() {
    this.getDataList()
    this.getMissionDataList()
  },
  methods: {
    parseTime,
    // 开关
    handleSwitch(id,status) {
      this.loading = true;
      switchScheduler({id: id,status:status}).then(response => {
        this.$message.success(response.msg)
        this.getDataList();
      }).catch(response => {
        this.$message.error(response.msg)
      })
      this.loading = false;
    },
    // 删除
    handleDelete(item) {
      this.$confirm("删除后将不可恢复, 是否继续?", "删除任务", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteScheduler({id: item}).then(response => {
          this.$message.success(response.msg)
          this.getDataList();
        })
      });
    },
    // 修改
    handleUpdate(item) {
      this.addDialog.title = '修改任务内容';
      this.addDialog.type = 'update';
      this.addDialog.open = true;
      this.preFrom = item
    },
    // 新增
    handleAdd() {
      this.addDialog.title = '新建任务';
      this.addDialog.type = 'add';
      this.addDialog.open = true;
    },
    // 获取导出记录
    handlerExportRecord() {
      return exportList({"exportType":12,"page":1,"size":70}).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
        this.exportRecord.open=true
        this.exportRecord.title="定时导出记录"
      })
    },
    // 查询所有定时任务
    getDataList() {
      this.loading = true;
      getSchedulerList().then(response => {
        this.DataList = response.data
      }).catch(response => {
        this.$message.error(response.msg)
      })
      this.loading = false;
    },
    getExportList(){
      return exportList(this.queryParams).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
      })
    },
    onSubmit() {
      const { name, minute, dateType, missionId } = this.preFrom; // 解构赋值
      // 验证任务名称、描述和行为
      if (!this.validateField(name, "定时名称不能为空") ||
          !this.validateField(minute,"频率任务不能为空") ||
          !this.validateField(dateType,"周期不能为空") ||
          !this.validateField(missionId,"执行任务不能为空")) {
        return;
      }
      this.loading = true;
      this.getForm(this.preFrom);
      if (this.addDialog.type === 'add'){
        addScheduler(this.form).then(response => {
          this.$message.success(response.msg);
          this.getDataList();
        }).catch(() => {
          this.$message.error("请求错误");
        })
      }else if (this.addDialog.type === 'update'){
        updateScheduler(this.form).then(response => {
          this.$message.success(response.msg);
          this.getDataList();
        }).catch(() => {
          this.$message.error("请求错误");
        })
      }

      this.resetForm();
      this.addDialog.open = false;
      this.loading = false;
    },
    // 修改任务内容
    updateSubmit(){
      const { missionName, actions } = this.updateMissionVisible.data; // 解构赋值
      // 验证任务名称、描述和行为
      if (!this.validateField(missionName, "任务名称不能为空") ||
          !this.validateField(minute,"频率任务不能为空") ||
          !this.validateField(date,"周期不能为空") ||
          !this.validateField(missionId,"执行任务不能为空")) {
        return;
      }
      // 判断所有 advertiser_id 是否都不为空
      const allAdvertiserIdsNotEmpty = actions.every(action =>
          action.advertiser_ids.every(advertiser => advertiser.advertiser_id)
      );
      if (!allAdvertiserIdsNotEmpty) {
        this.$message.error("账户ID不能为空");
        return;
      }
      this.loading = true;
      this.getForm(this.updateMissionVisible.data);
      updateMission(this.form).then(response =>{
        this.$message.success(response.msg);
        this.updateMissionVisible.open = false;
        this.getDataList();
      }).catch(() => {
        this.$message.error("请求错误");
      })
      this.resetForm();
      this.loading = false;
    },
    // 获取任务列表信息
    getMissionDataList() {
      this.loading = true;
      getMissionList().then(response => {
        this.missionDataList = response.data
      }).catch(response => {
        this.$message.error(response.msg)
      })
      this.loading = false;
    },
    getMissionName(id){
      for (let i = 0; i < this.missionDataList.length; i++) {
        if (this.missionDataList[i].id === id) {
          return this.missionDataList[i].missionName;
        }
      }
      return id;
    },
    // 格式化表单
    getForm(data) {
      this.form = cloneDeep(data);
    },
    // 获取日期选择器 日期
    getDate(value) {
      if (this.createTime) {
        this.preFrom.create_start_time = this.createTime[0]
        this.preFrom.create_end_time = this.createTime[1]
      }
      if (value == null) {
        this.createTime = undefined // 清空开始时间和结束时间
        this.preFrom.create_start_time = ''
        this.preFrom.create_end_time = ''
      }
    },
    // 重置新增任务列表
    resetForm() {
      this.preFrom = {
            name: undefined,
            missionId: undefined,
            minute: 30,
            dateType: 1,
            status: undefined,
      }
      this.form = {};
    },
    // 行为类型转换
    getActionType(item) {
      const actionType = {
        1: '星视频一体式导出',
        2: '星任务(视频)一体式导出',
        3: '星视频助推分时数据',
        4: '星任务(视频)助推分时数据',
      };
      return actionType[item];
    },
    // 日期类型转换
    getDateType(dateTpye) {
      const dateTypeMap = {
        1: '最近一天',
        2: '最近一周',
        3: '最近一个月',
        4: '最近两个月',
        5: '最近三个月',
        6: '最近半年',
        7: '最近一年'
      };
      return dateTypeMap[dateTpye] || '未选择日期';
    },
    // 校验函数
    validateField(field, message){
      if (!field) {
        this.$message.error(message);
        return false;
      }
      return true;
    }
  }
}
</script>
<style>
.container {
  height: auto;
  display: flex;
  flex-direction: column;
}
</style>
