<template>
  <div class="app-container home" style="display: flex;align-items: center;justify-content: center;width: auto;">
    <div class="index-container"><h1><span class="title1">快速入门</span></h1>
      <h2 id="1.什么是快手 Marketing API" level="h2"><span class="title">1.什么是快手 Marketing API</span></h2>
      <p>快手 Marketing API 是一套投放平台功能的接口服务，通过 Marketing API 可以帮助广告客户构建数据闭环，客户可接入
        Marketing API 将自有平台与快手广告投放平台整合打通，并且实现广告投放过程中的各项功能。</p>
      <h2 id="2.注册成为聚星 Marketing API 的开发者全流程介绍" level="h2"><span class="title">2.注册成为聚星 Marketing API 的开发者全流程介绍</span>
      </h2>
      <p><strong>目前磁力聚星MAPI已支持星视频订单查询、星任务订单数据查询和流量助推订单数据查询，暂不支持星直播</strong>
      </p>
      <p><strong>整体流程</strong>：</p>
      <p>（1） 注册开发者：在开放平台申请成为开发者</p>
      <p>（2） 获取平台授权：开放平台审核通过后，填写应用管理进而获取APP ID，申请成功后，开放平台的页面会展示APP
        ID，Secret，授权URL等信息</p>
      <p>（3）申请聚星权限：应用管理中，<strong>服务场景勾选「磁力聚星」</strong>；</p>
      <p>（4） 聚星授权：使用<strong>聚星账号密码登陆开放平台（用于主体信息校验）</strong>，再打开授权URL，完成授权</p>
      <p>（5）完成对接：请求并获取Token，完成对接；</p>
      <p><img alt="img" src="https://p1-yx.adkwai.com/kos/nlav11947/openapi/assets/images/doc/img_1.2.png"></p>
      <h2 id="注册成为开发者流程：" level="h2"><span class="title">注册成为开发者流程：</span></h2>
      <h3 id="1.登陆快手 id" level="h3"><span class="title">1.登陆快手 id</span></h3>
      <p><img alt="img" src="https://p1-yx.adkwai.com/kos/nlav11947/openapi/assets/images/doc/img_2.1.png"></p>
      <h3 id="2.点击页面右上角注册成为开发者" level="h3"><span class="title">2.点击页面右上角注册成为开发者</span></h3>
      <p><img alt="img" src="https://p1-yx.adkwai.com/kos/nlav11947/openapi/assets/images/doc/img_2.2.png"></p>
      <h3 id="3.完善联系人资料" level="h3"><span class="title">3.完善联系人资料</span></h3>
      <p><img alt="img" src="https://p1-yx.adkwai.com/kos/nlav11947/openapi/assets/images/doc/img_3.1.png"></p>
      <h3 id="4.完善开发者资质" level="h3"><span class="title">4.完善开发者资质</span></h3>
      <p>可以由公司法人 or 代理人注册申请，但是均需要申请人进行实名身份认证。
        企业认证授权书：<a href="https://yiqixie.com/e/ydisk/v/home/fcAB9hBt0jzjgRXwjID7btjKN">https://yiqixie.com/e/ydisk/v/home/fcAB9hBt0jzjgRXwjID7btjKN</a>
      </p>
      <p><img alt="img" src="https://p1-yx.adkwai.com/kos/nlav11947/openapi/assets/images/doc/img_4.1.png"></p>
      <p><img alt="img" src="https://p1-yx.adkwai.com/kos/nlav11947/openapi/assets/images/doc/img_4.2.png"></p>
      <p><strong>5.开发者角色：</strong></p>
      <p><strong>广告主：</strong>务必填写与注册成为快手开发者主体资质相同的快手广告投放账户 ID。（暂无代理商角色）</p>
      <p><strong>服务商：</strong>签订服务商入驻合同后，将服务商审批通过合同传与此处。</p>
      <p><img alt="img" src="https://p1-yx.adkwai.com/kos/nlav11947/openapi/assets/images/doc/img_5.1.png"></p>
    </div>
  </div>

</template>

<script>

import { mapActions } from 'vuex'
export default {
  name: 'Index',
  data() {
    return {
      // 版本号
      version: '4.1.0'
    }
  },
  methods: {
    ...mapActions(['GetUserInfo'])
  },
  mounted: function() {
    // 获取 auth_code 参数值
    const authCode = this.$route.params.auth_code || this.$route.query.auth_code
    if (authCode) {
      this.GetUserInfo(authCode).then(res => {
        this.$message.success('授权成功')
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.index-container{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,sans-serif,Din condensed,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol;
  font-variant: tabular-nums;
  font-feature-settings: "tnum","tnum";
  box-sizing: border-box;
  line-height: 22px;
  font-size: 14px;
  color: #434343;
}
.title1{
  font-size: 32px;
}
img{
  width: 900px;
}
</style>

