import { getData } from './request.js'

export function getJXAccountList(data) {
    return getData({
        url: 'mapi/jx_account/list',
        method: 'get',
        data: data
    })
}

/**
 * 新建任务
 */
export function addJXAccount(data) {
    return getData({
        url: 'mapi/jx_account/add',
        method: 'post',
        data: data
    })
}

/**
 * 修改任务
 */
export function updateJXAccount(data) {
    return getData({
        url: 'mapi/jx_account/update',
        method: 'put',
        data: data
    })
}

/**
 * 删除任务
 */
export function deleteJXAccount(data) {
    return getData({
        url: 'mapi/jx_account/delete',
        method: 'delete',
        data: data
    })
}


