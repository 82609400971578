import { getData } from './request.js'

/**
 * 修改出价
 */
export function unitPriceUpdate(data) {
    return getData({
        url: 'mapi/jx_handle/update/unit_price',
        method: 'post',
        data: data
    })
}

export function alterBudgetUpdate(data) {
    return getData({
        url: 'mapi/jx_handle/update/alter_budget',
        method: 'post',
        data: data
    })
}


export function promotionTimeUpdate(data) {
    return getData({
        url: 'mapi/jx_handle/update/promotion_time',
        method: 'post',
        data: data
    })
}


export function upgradePromotionTime(data) {
    return getData({
        url: 'mapi/jx_handle/update/upgrade_promotion_time',
        method: 'post',
        data: data
    })
}

export function createSupplementOrder(data) {
    return getData({
        url: 'mapi/jx_handle/supplement_order/create',
        method: 'post',
        data: data
    })
}

export function stopOrder(data) {
    return getData({
        url: 'mapi/jx_handle/supplement_order/stop',
        method: 'post',
        data: data
    })
}

export function exportSupplement(data) {
    return getData({
        url: 'mapi/jx_handle/supplement_order/export',
        method: 'post',
        data: data
    })
}

export function exportPostList(data) {
    return getData({
        url: 'mapi/jx_handle/post_list/export',
        method: 'post',
        data: data
    })
}

export function exportStarVideoIntegration(data) {
    return getData({
        url: 'mapi/jx_handle/integration/star_video/export',
        method: 'post',
        data: data
    })
}
